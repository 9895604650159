<!-- Using a custom css modal instead of mt-modal-wrapper because this component can be opened inside an already-opened modal, while mt-modal-wrapper cannot handle stacked modals. mt-modal-wrapper internally uses sweetalerts2, and it is a known issue: https://github.com/sweetalert2/sweetalert2/issues/606#issuecomment-325697220 -->
<div class="popup-backdrop" #modalDiv [ngStyle]="customBackdropStyles">
    <div class="miles-card popup" [ngStyle]="styles">
        <span *ngIf="options?.showCloseButton" class="popup-close-btn" (click)="onClose.emit()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
        <div>
            <h1>{{ title }}</h1>
        </div>
        <div [ngStyle]="{ width: '100%' }">
            <ng-content></ng-content>
            <div class="flex-center gap-sm">
                <button *ngIf="options?.showConfirmButton" (click)="onConfirm.emit()" class="btn btn-flat btn-success">Okay</button>
                <button *ngIf="options?.showCancelButton" (click)="onClose.emit()" class="btn btn-light">Cancel</button>
            </div>
        </div>
    </div>
</div>
